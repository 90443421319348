const html = `
<body data-home-page="MiGob.html" data-home-page-title="MiGob" class="u-body">
    <section class="u-align-center u-clearfix u-image u-shading u-section-1" id="carousel_d1b7">
      <div class="u-clearfix u-sheet u-sheet-1">
        <h1 class="u-text u-text-1">Primer Nube GRP para Gobiernos en México&nbsp;</h1>
        <h2 class="u-heading-font u-subtitle u-text u-text-custom-color-1 u-text-2">Hacia una
          Administración Pública
          Inteligente</h2>
        <div class="u-border-6 u-border-white u-line u-line-horizontal u-line-1"></div>
        <img src="images/MiGobMiCuentalogos.png" alt="" class="u-image u-image-default u-image-1"
          data-image-width="500" data-image-height="256">
      </div>
    </section>
    <section class="u-align-left u-clearfix u-valign-middle-md u-valign-middle-sm u-valign-top-xs
      u-section-2" id="carousel_a2ea">
      <div class="u-clearfix u-expanded-width-md u-expanded-width-sm u-expanded-width-xs
        u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-left u-container-style u-custom-color-1 u-layout-cell u-left-cell
              u-size-15 u-size-30-md u-layout-cell-1">
              <div class="u-container-layout u-valign-middle u-container-layout-1">
                <img src="images/alcaldia_blanca.png" alt="" class="u-image u-image-default
                  u-image-1">
              </div>
            </div>
            <div class="u-align-left u-container-style u-custom-color-3 u-layout-cell u-size-15-lg
              u-size-15-xl u-size-22-sm u-size-22-xs u-size-30-md u-layout-cell-2">
              <div class="u-container-layout u-container-layout-2">
                <div class="u-border-3 u-border-custom-color-1 u-expanded-width u-line
                  u-line-horizontal u-line-1"></div>
                <h1 class="u-custom-font u-font-montserrat u-text u-text-1">Sistema Gobierno
                  Electrónico</h1>
              </div>
            </div>
            <div class="u-align-justify u-container-style u-custom-color-3 u-layout-cell
              u-size-17-sm u-size-17-xs u-size-24-lg u-size-24-xl u-size-59-md u-layout-cell-3">
              <div class="u-container-layout u-container-layout-3">
                <p class="u-text u-text-2">Tecnología de punta basada en Autogestión Ciudadana +
                  Cero Papel + Gobierno Electrónico + Eficiencia Administrativa.<br>
                  <br>Servicios de gobierno centrados en el ciudadano
                  mediante Cuenta Única Universal (MiCuenta.Digital) y
                  acceso desde la palma de su mano.<br>
                </p>
              </div>
            </div>
            <div class="u-container-style u-custom-color-3 u-hidden-sm u-hidden-xs u-layout-cell
              u-right-cell u-size-1-md u-size-1-sm u-size-1-xs u-size-6-lg u-size-6-xl
              u-layout-cell-4">
              <div class="u-container-layout u-container-layout-4"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="u-clearfix u-layout-wrap u-layout-wrap-2">
        <div class="u-layout">
          <div class="u-layout-row">
            <div class="u-container-style u-layout-cell u-left-cell u-size-18-lg u-size-18-xl
              u-size-19-md u-size-19-sm u-size-19-xs u-layout-cell-5">
              <div class="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-top-sm
                u-valign-top-xs u-container-layout-5">
                <img src="images/migob_modulos.png" alt="" class="u-image u-image-default
                  u-image-2">
              </div>
            </div>
            <div class="u-align-justify-sm u-align-justify-xs u-align-left-lg u-align-left-md
              u-align-left-xl u-container-style u-layout-cell u-right-cell u-size-41-md u-size-41-sm
              u-size-41-xs u-size-42-lg u-size-42-xl u-layout-cell-6">
              <div class="u-container-layout u-container-layout-6">
                <p class="u-text u-text-custom-color-3 u-text-default u-text-3">Somos la primer nube
                  pública para gobiernos Estatales, Municipales y Paramunicipales, completamente
                  modular, adaptable al tamaño y necesidades de la entidad pública.<br>
                  <br>Fácil de adquirir e implementar.<br>Módulos de autogestión innovadores.<br>Infraestructura
                  tecnológica y datos en territorio mexicano.<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="u-align-center u-clearfix u-section-3" id="carousel_b5cb">
      <div class="u-clearfix u-sheet u-sheet-1">
        <h3 class="u-text u-text-default u-text-1">MÓDULOS</h3>
        <div class="u-expanded-width u-list u-repeater u-list-1">
          <div class="u-align-left u-container-style u-list-item u-repeater-item">
            <div class="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs
              u-container-layout-1">
              <div class="u-container-style u-group u-group-1">
                <div class="u-container-layout u-container-layout-2">
                  <h5 class="u-text u-text-2">SIR<br>INGRESOS:
                  </h5>
                  <p class="u-text u-text-3">Recaudación, Contribuciones, <br>Catastro, Cuenta
                    Única, <br>Convenios, Ejecución Fiscal
                  </p>
                </div>
              </div>
              <img src="images/sir.png" alt="" class="u-image u-image-default u-preserve-proportions
                u-image-1" data-image-width="462" data-image-height="462">
            </div>
          </div>
          <div class="u-align-left u-container-style u-list-item u-repeater-item">
            <div class="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs
              u-container-layout-3">
              <div class="u-container-style u-group u-group-2">
                <div class="u-container-layout u-container-layout-4">
                  <h5 class="u-text u-text-4">RECURSOS MATERIALES</h5>
                  <p class="u-text u-text-5">Adquisiciones, Control de Activos,
                    Combustible, Control de Inventario
                    y Control de Obra Pública.</p>
                </div>
              </div>
              <img src="images/sac.png" alt="" class="u-image u-image-default u-preserve-proportions
                u-image-2">
            </div>
          </div>
          <div class="u-align-left u-container-style u-list-item u-repeater-item">
            <div class="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs
              u-container-layout-5">
              <div class="u-container-style u-group u-group-3">
                <div class="u-container-layout u-valign-top-xl u-container-layout-6">
                  <h5 class="u-text u-text-6">RECURSOS HUMANOS</h5>
                  <p class="u-text u-text-7">Nómina, Reclutamiento, Expedientes,
                    Declaración Patrimonial,
                    Acceso y Asistencia</p>
                </div>
              </div>
              <img src="images/rh.png" alt="" class="u-image u-image-default u-preserve-proportions
                u-image-3">
            </div>
          </div>
          <div class="u-align-left u-container-style u-list-item u-repeater-item">
            <div class="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs
              u-container-layout-7">
              <div class="u-container-style u-group u-group-4">
                <div class="u-container-layout u-valign-top-xl u-container-layout-8">
                  <h5 class="u-text u-text-8">ATENCIÓN CIUDADANA</h5>
                  <p class="u-text u-text-9">Trámites y Servicios, Citas, <br>Apoyos Sociales y
                    Peticiones Ciudadanas
                  </p>
                </div>
              </div>
              <img src="images/ac.png" alt="" class="u-image u-image-default u-preserve-proportions
                u-image-4">
            </div>
          </div>
          <div class="u-align-left u-container-style u-list-item u-repeater-item">
            <div class="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs
              u-container-layout-7">
              <div class="u-container-style u-group u-group-4">
                <div class="u-container-layout u-valign-top-xl u-container-layout-8">
                  <h5 class="u-text u-text-8">GOBIERNO DIGITAL</h5>
                  <p class="u-text u-text-9">Portales, APPs, Firma lectrónica, <br>Facturación
                    Electrónica, Pago en línea
                    / Autoservicios / Móviles
                  </p>
                </div>
              </div>
              <img src="images/gd.png" alt="" class="u-image u-image-default u-preserve-proportions
                u-image-5">
            </div>
          </div>
          <div class="u-align-left u-container-style u-list-item u-repeater-item">
            <div class="u-container-layout u-similar-container u-valign-top-sm u-valign-top-xs
              u-container-layout-7">
              <div class="u-container-style u-group u-group-4">
                <div class="u-container-layout u-valign-top-xl u-container-layout-8">
                  <h5 class="u-text u-text-8">CUADRO DE MANDO</h5>
                  <p class="u-text u-text-9">BSC: Seguimiento Compromisos, <br>Gestión Estratégica,
                    Georreferencia, Transparencia
                  </p>
                </div>
              </div>
              <img src="images/cm.png" alt="" class="u-image u-image-default u-preserve-proportions
                u-image-6">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="u-clearfix u-section-4" id="sec-189e">
      <div class="u-clearfix u-sheet u-valign-middle-xl u-valign-middle-xs u-sheet-1">
        <img src="images/modulos1.png" alt="" class="u-align-center u-expanded-width-xs u-image
          u-image-default u-image-1" data-image-width="3025" data-image-height="2196">
      </div>
    </section>
    <section class="u-align-left u-clearfix u-valign-middle-md u-valign-middle-sm u-valign-middle-xs
      u-section-5" id="carousel_c6ea">
      <div class="u-clearfix u-expanded-width-md u-expanded-width-sm u-expanded-width-xs
        u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-container-style u-custom-color-3 u-hidden-sm u-hidden-xs u-layout-cell
              u-right-cell u-size-4-md u-size-6-lg u-size-6-sm u-size-6-xl u-size-6-xs
              u-layout-cell-1">
              <div class="u-container-layout u-container-layout-1"></div>
            </div>
            <div class="u-align-center-sm u-align-center-xs u-align-left-lg u-align-left-md
              u-align-left-xl u-container-style u-custom-color-3 u-layout-cell u-size-17-sm
              u-size-17-xl u-size-17-xs u-size-18-lg u-size-56-md u-layout-cell-2">
              <div class="u-container-layout u-container-layout-2">
                <div class="u-border-3 u-border-custom-color-1 u-expanded-width u-line
                  u-line-horizontal u-line-1"></div>
                <h1 class="u-custom-font u-font-montserrat u-text u-text-1">GOBIERNO<br>EN LA PALMA<br>DE
                  TU MANO
                </h1>
              </div>
            </div>
            <div class="u-align-justify u-container-style u-custom-color-3 u-layout-cell
              u-size-21-lg u-size-22-sm u-size-22-xl u-size-22-xs u-size-59-md u-layout-cell-3">
              <div class="u-container-layout u-container-layout-3">
                <p class="u-text u-text-2">APP
                  móvil que administra la identidad digital del ciudadano y le facilita el acceso
                  a pagos, trámites y servicios la información es controlada por el ciudadano.</p>
              </div>
            </div>
            <div class="u-align-left u-container-style u-custom-color-1 u-layout-cell u-left-cell
              u-size-1-md u-size-1-sm u-size-1-xs u-size-15-lg u-size-15-xl u-layout-cell-4">
              <div class="u-container-layout u-valign-top-lg u-valign-top-xl u-container-layout-4">
                <img src="images/celularconsuapp.png" alt="" class="u-expanded-width-xl u-image
                  u-image-default u-image-1" data-image-width="512" data-image-height="512">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="u-clearfix u-expanded-width-sm u-layout-wrap u-layout-wrap-2">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-justify-sm u-align-justify-xs u-align-left-lg u-align-left-md
              u-align-left-xl u-container-style u-layout-cell u-size-60 u-layout-cell-5">
              <div class="u-container-layout u-container-layout-5">
                <ul class="u-custom-list u-text u-text-custom-color-3 u-text-3">
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);">
                        <circle style="fill:currentColor;" cx="29" cy="29" r="29"></circle>
                        <polygon style="fill:#FFFFFF;" points="44,29 22,44 22,29.273 22,14"></polygon>
                        <path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path>
                      </svg>
                    </div>Consulta de todos los trámites y
                    servicios (ReTyS)
                    con sus requisitos y costos.
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Mejorar los servicios públicos
                    mediante la participación ciudadana en línea.
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Acceso a consulta de pagos
                    de&nbsp; trámites y servicios de manera fácil
                    y rápida.
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Buzón electrónico para notificar
                    seguimiento y resoluciones de trámites y servicios.
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Acceso a información pública.&nbsp;<br>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="images/APPMICUENTAENTUMANO.png" alt="" class="u-image u-image-default u-image-2"
        data-image-width="2415" data-image-height="2767">
    </section>
    <section class="u-align-left u-clearfix u-valign-middle-md u-valign-middle-xl u-valign-top-sm
      u-valign-top-xs u-section-6" id="carousel_5119">
      <div class="u-clearfix u-expanded-width-sm u-expanded-width-xs u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-left u-container-style u-layout-cell u-left-cell u-size-15
              u-size-30-md u-white u-layout-cell-1">
              <div class="u-container-layout u-valign-bottom-md u-valign-middle-xl u-valign-top-sm
                u-valign-top-xs u-container-layout-1">
                <img src="images/MicuentaDigital.png" alt="" class="u-image u-image-default
                  u-image-1" data-image-width="1086" data-image-height="547">
              </div>
            </div>
            <div class="u-align-left u-container-style u-custom-color-3 u-layout-cell u-size-15-lg
              u-size-15-xl u-size-22-sm u-size-22-xs u-size-30-md u-layout-cell-2">
              <div class="u-container-layout u-container-layout-2">
                <div class="u-border-3 u-border-custom-color-1 u-expanded-width u-line
                  u-line-horizontal u-line-1"></div>
                <h1 class="u-align-center-sm u-align-center-xs u-custom-font u-font-montserrat
                  u-text u-text-1">Identidad<br>Ciudadana
                </h1>
              </div>
            </div>
            <div class="u-container-style u-custom-color-3 u-layout-cell u-size-17-sm u-size-17-xs
              u-size-24-lg u-size-24-xl u-size-58-md u-layout-cell-3">
              <div class="u-container-layout u-container-layout-3">
                <p class="u-align-justify u-text u-text-default u-text-2">Plataforma que permite a
                  los ciudadanos acceso sencillo y seguro a servicios electrónicos de Gobierno,
                  dándole el total control de su información
                  personal.</p>
                <p class="u-text u-text-3">
                  <a class="u-active-none u-border-none u-btn u-button-link u-button-style
                    u-hover-none u-none u-text-custom-color-1 u-btn-1"
                    href="https://micuenta.digital">Más información</a>
                </p>
              </div>
            </div>
            <div class="u-container-style u-custom-color-3 u-hidden-sm u-hidden-xs u-layout-cell
              u-right-cell u-size-2-md u-size-2-sm u-size-2-xs u-size-6-lg u-size-6-xl
              u-layout-cell-4">
              <div class="u-container-layout u-container-layout-4"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="u-clearfix u-layout-wrap u-layout-wrap-2">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-justify u-container-style u-layout-cell u-size-30 u-layout-cell-5">
              <div class="u-container-layout u-container-layout-5">
                <p class="u-text u-text-custom-color-3 u-text-default u-text-4">Con micuentaDIGITAL
                  el ciudadano puede registrar y
                  controlar su información y decidir con que gobierno ”conectarse” para compartir
                  los datos necesarios para algún trámite o servicio así como muchos servicios
                  mas como pagos en línea, consulta de trámites , reportes ciudadanos entre otros
                  mas contando con un canal de comunicación bidireccional con gobierno para un
                  seguimiento puntual.</p>
              </div>
            </div>
            <div class="u-align-left u-container-style u-layout-cell u-size-30 u-layout-cell-6">
              <div class="u-container-layout u-valign-bottom-xs u-valign-top-lg
                u-container-layout-6">
                <ul class="u-custom-list u-text u-text-custom-color-3 u-text-5">
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Multi-Municipio&nbsp;
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Identidad
                    Digital&nbsp;
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Seguridad&nbsp;
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Buzón&nbsp;
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Portafolio
                    Digital&nbsp;
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Requisitos&nbsp;
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Notificaciones
                    electrónicas&nbsp;
                  </li>
                  <li>
                    <div class="u-list-icon">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                        xml:space="preserve" class="u-svg-content" viewbox="0 0 58 58" x="0px"
                        y="0px" style="color: rgb(235, 186, 22);"><circle style="fill:currentColor;"
                          cx="29" cy="29" r="29"></circle><polygon style="fill:#FFFFFF;"
                          points="44,29 22,44 22,29.273 22,14"></polygon><path style="fill:#FFFFFF;"
                          d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                          c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                          l-22,15C22.394,44.941,22.197,45,22,45z
                          M23,15.893v26.215L42.225,29L23,15.893z"></path></svg>
                    </div>Seguimiento
                    de tramites
                  </li>
                </ul>
                <img src="images/86-3.png" alt="" class="u-image u-image-default u-image-2"
                  data-image-width="601" data-image-height="618">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="u-clearfix u-hidden-sm u-hidden-xs u-image u-section-7" id="carousel_b653">
      <div class="u-clearfix u-sheet u-sheet-1">
        <div class="u-clearfix u-expanded-width-md u-expanded-width-sm u-expanded-width-xs
          u-gutter-0 u-layout-wrap u-layout-wrap-1">
          <div class="u-gutter-0 u-layout">
            <div class="u-layout-col">
              <div class="u-size-30">
                <div class="u-layout-row">
                  <div class="u-align-left u-container-style u-layout-cell u-size-20
                    u-layout-cell-1">
                    <div class="u-container-layout u-valign-top-lg u-valign-top-md u-valign-top-xl
                      u-container-layout-1">
                      <img class="u-expand-resize u-expanded-width-lg u-expanded-width-md
                        u-expanded-width-xl u-image u-image-1" src="images/transparencia.jpg"
                        data-image-width="209" data-image-height="342">
                    </div>
                  </div>
                  <div class="u-container-style u-layout-cell u-size-20 u-layout-cell-2">
                    <div class="u-container-layout u-container-layout-2">
                      <img src="images/reporte.jpg" alt="" class="u-expanded-width-lg
                        u-expanded-width-md u-expanded-width-xl u-image u-image-default u-image-2"
                        data-image-width="209" data-image-height="342">
                    </div>
                  </div>
                  <div class="u-container-style u-layout-cell u-size-20 u-layout-cell-3">
                    <div class="u-container-layout u-valign-top-lg u-valign-top-md u-valign-top-xl
                      u-container-layout-3">
                      <img src="images/consultas.jpg" alt="" class="u-expanded-width-lg
                        u-expanded-width-md u-expanded-width-xl u-image u-image-default u-image-3"
                        data-image-width="209" data-image-height="341">
                    </div>
                  </div>
                </div>
              </div>
              <div class="u-size-30">
                <div class="u-layout-row">
                  <div class="u-container-style u-hidden-sm u-hidden-xs u-layout-cell u-size-20
                    u-layout-cell-4">
                    <div class="u-container-layout u-valign-top u-container-layout-4">
                      <img src="images/predial.jpg" alt="" class="u-image u-image-default u-image-4"
                        data-image-width="209" data-image-height="341">
                    </div>
                  </div>
                  <div class="u-align-left u-container-style u-layout-cell u-size-20
                    u-layout-cell-5">
                    <div class="u-container-layout u-valign-top-lg u-valign-top-md u-valign-top-xl
                      u-container-layout-5">
                      <img src="images/residencia.jpg" alt="" class="u-image u-image-default
                        u-image-5" data-image-width="209" data-image-height="342">
                    </div>
                  </div>
                  <div class="u-container-style u-layout-cell u-size-20 u-layout-cell-6">
                    <div class="u-container-layout u-valign-top-lg u-valign-top-md u-valign-top-xl
                      u-container-layout-6">
                      <img src="images/permiso.jpg" alt="" class="u-image u-image-default u-image-6"
                        data-image-width="259" data-image-height="343">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="u-clearfix u-section-8" id="carousel_f864">
      <div class="u-clearfix u-sheet u-sheet-1">
        <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div class="u-layout">
            <div class="u-layout-row">
              <div class="u-align-left u-container-style u-layout-cell u-right-cell
                u-shape-rectangle u-size-30 u-layout-cell-1">
                <div class="u-container-layout u-container-layout-1">
                  <div class="u-form u-form-1">
                    <form action="scripts/form-carousel_f864.php" method="POST" class="u-clearfix
                      u-form-spacing-10 u-form-vertical u-inner-form" style="padding: 10px"
                      source="customphp" name="form">
                      <div class="u-form-email u-form-group u-form-partition-factor-2
                        u-form-group-1">
                        <label for="email-b04a" class="u-form-control-hidden u-label">Email</label>
                        <input type="email" placeholder="Correo Electrónico" id="email-b04a"
                          name="email" class="u-grey-75 u-input u-input-rectangle u-input-1"
                          required="">
                      </div>
                      <div class="u-form-group u-form-name u-form-partition-factor-2
                        u-form-group-2">
                        <label for="name-b04a" class="u-form-control-hidden u-label">Name</label>
                        <input type="text" placeholder="Nombre" id="name-b04a" name="name"
                          class="u-grey-75 u-input u-input-rectangle u-input-2" required="">
                      </div>
                      <div class="u-form-address u-form-group u-form-partition-factor-2
                        u-form-group-3">
                        <label for="address-920c" class="u-form-control-hidden u-label">Address</label>
                        <input type="text" placeholder="Ciudad" id="address-920c" name="address"
                          class="u-grey-75 u-input u-input-rectangle u-input-3" required="">
                      </div>
                      <div class="u-form-group u-form-partition-factor-2 u-form-phone
                        u-form-group-4">
                        <label for="phone-6f45" class="u-form-control-hidden u-label">Phone</label>
                        <input type="tel"
                          pattern="+?d{0,2}[s(-]?([0-9]{3})[s)-]?([s-]?)([0-9]{3})[s-]?([0-9]{2})[s-]?([0-9]{2})"
                          placeholder="N° de Teléfono" id="phone-6f45" name="phone" class="u-grey-75
                          u-input u-input-rectangle u-input-4" required="">
                      </div>
                      <div class="u-form-group u-form-message u-form-group-5">
                        <label for="message-b04a" class="u-form-control-hidden u-label">Message</label>
                        <textarea placeholder="Dudas o Comentario" rows="4" cols="50"
                          id="message-b04a" name="message" class="u-grey-75 u-input
                          u-input-rectangle u-input-5" required=""></textarea>
                      </div>
                      <div class="u-align-left u-form-group u-form-submit u-form-group-6">
                        <a href="https://nicepage.com" class="u-btn u-btn-submit u-button-style
                          u-custom-color-1 u-btn-1">enviar</a>
                        <input type="submit" value="submit" class="u-form-control-hidden">
                      </div>
                      <div class="u-form-send-message u-form-send-success"> Thank you! Your message
                        has been sent. </div>
                      <div class="u-form-send-error u-form-send-message"> Unable to send your
                        message. Please fix errors then try again. </div>
                      <input type="hidden" value="" name="recaptchaResponse">
                    </form>
                  </div>
                </div>
              </div>
              <div class="u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-2">
                <div class="u-container-layout u-container-layout-2">
                  <h5 class="u-text u-text-1">¡Nos encantaría saber de ti!</h5>
                  <h2 class="u-text u-text-custom-color-1 u-text-2">Contáctanos</h2>
                  <p class="u-text u-text-3"><b>Solicita una reunión al 3314107052</b> para recibir
                    más información y
                    conoce todos los beneficios que traerá a tu municipio la implementación de
                    nuestra tecnología.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <footer class="u-align-left u-clearfix u-custom-color-1 u-footer u-footer" id="sec-d012"><div
        class="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-xl u-sheet-1">
        <img src="images/logomigobhorizontal.png" alt="" class="u-image u-image-default u-image-1"
          data-image-width="1240" data-image-height="277">
      </div></footer>
  </body>
`;

export default html;
