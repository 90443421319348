/**
 * Root Reducer
 */
// Dependencies
import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';

// Reducers
import auth from './reducers/auth';
import app from './reducers/app';
import catalogs from './reducers/catalogs';

const config = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'app',
    'catalogs',
  ],
};

export default () => persistCombineReducers(config, {
  auth,
  app,
  catalogs,
});
