import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form, Input, Col, Grid,
} from 'antd';
import PropTypes from 'prop-types';
import { SET_LADAS } from 'store/reducers/catalogs';
import Select from 'components/Select';
import { setLadas } from 'api/catalogos';

const defaultNames = {
  lada: 'lada',
  extension: 'extension',
  numero: 'numero_de_telefono',
};

const LadaNumero = ({
  names,
  fullSize,
  disabled,
  breakPoints: bPoints,
  ladaOnly,
  hasFeedback,
  extension,
  required,
  ladaDefault: ladaDef,
}) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-param-reassign
  names = { ...defaultNames, ...names };
  const screens = Grid.useBreakpoint();
  const entensionRef = useRef();
  const numberRef = useRef();
  const [requiredNum, setRequiredNum] = useState(required || false);
  const [requiredLadaExt, setRequiredLadaExt] = useState(required || false);
  const ladas = useSelector(({ catalogs }) => catalogs.ladas);
  const ladaDefaultID = useSelector(({ auth }) => auth.entidad?.configuracion?.lada);
  const ladaDefault = ladas.find((e) => e.id === (ladaDef || ladaDefaultID));
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);

  React.useEffect(() => {
    if (!ladas.length) {
      setLadas(dispatch, SET_LADAS);
    }
    // eslint-disable-next-line
  }, []);

  const rules = {
    [names.numero]: [
      {
        required: requiredNum,
        message: 'El campo es requerido',
      },
    ],
    [names.extension]: [
      {
        required: requiredLadaExt,
        message: 'Requerido',
      },
    ],
    [names.lada]: [
      {
        required: requiredLadaExt && (extension ? true : !ladaDefaultID),
        message: 'Requerido',
      },
    ],
  };

  const getBpoint = () => {
    if (!collapsedSidebar) {
      if (screens.md && !screens.lg && !screens.xl && !screens.xxl) {
        return 16;
      }
    }
    return 8;
  };

  const mdBp = bPoints.md || getBpoint();
  const lgBp = bPoints.lg || getBpoint();
  const xlBp = bPoints.xl || getBpoint();
  const xxlBp = bPoints.xxl || getBpoint();

  const defaultBreakPoints = {
    xs: 24,
    sm: 24,
    md: mdBp,
    lg: lgBp,
    xl: xlBp,
    xxl: xxlBp,
  };

  const span = { span: 24 };
  const breakPoints = (fullSize ? span : defaultBreakPoints);

  const Lada = () => (
    <Form.Item
      rules={rules[names.lada]}
      name={names.lada}
      hasFeedback={hasFeedback}
      label="Lada"
    >
      <Select
        allowClear
        filterOption={(input, option) => {
          const normalizedInput = input.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '').toLowerCase();
          const children = option?.label?.props?.children;
          const normalizedText = `${children[0]}${children[2]}`
            .normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
          return normalizedText.indexOf(normalizedInput) >= 0;
        }}
        style={{ width: ladaOnly ? '100%' : 100 }}
        dropdownClassName="lada-dropdown wrap-text"
        disabled={disabled}
        placeholder={!disabled ? ladaDefault?.codigo : ''}
        dataSource={ladas}
        render={(item) => (
          <>
            {item.codigo}
            <br />
            {item.pais}
          </>
        )}
        onChange={(val) => {
          if (!required) {
            setRequiredNum(!!val);
          }
        }}
      />
    </Form.Item>
  );

  const integerNormalizer = (val) => parseInt(`0${val}`, 10) || null;

  const Extension = () => (
    <Form.Item
      label="Extensión"
      name={names.extension}
      rules={rules[names.extension]}
      hasFeedback={hasFeedback}
      normalize={integerNormalizer}
    >
      <Input
        allowClear
        maxLength={5}
        ref={entensionRef}
        style={{ width: 100 }}
        onChange={({ target: { value } }) => {
          if (!required) {
            setRequiredNum(!!value);
            setTimeout(() => {
              entensionRef.current.focus();
            });
          }
        }}
      />
    </Form.Item>
  );

  const Numero = () => (
    <Form.Item
      label="Número"
      name={names.numero}
      rules={rules[names.numero]}
      hasFeedback={hasFeedback}
      normalize={integerNormalizer}
      style={{ flex: 1 }}
    >
      <Input
        allowClear
        maxLength={10}
        ref={numberRef}
        disabled={disabled}
        onChange={({ target: { value } }) => {
          if (!required) {
            setRequiredLadaExt(!!value);
            setTimeout(() => {
              numberRef.current.focus();
            });
          }
        }}
      />
    </Form.Item>
  );

  const LeftComponent = () => (extension ? <Extension /> : <Lada />);

  if (ladaOnly) {
    return <Lada />;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Col {...breakPoints}>
      <Form.Item noStyle>
        <Input.Group className="select-input lada-num" style={{ display: 'flex' }} compact>
          <LeftComponent />
          <Numero />
        </Input.Group>
      </Form.Item>
    </Col>
  );
};

LadaNumero.propTypes = {
  names: PropTypes.exact({
    lada: PropTypes.string,
    extension: PropTypes.string,
    numero: PropTypes.string,
  }),
  fullSize: PropTypes.bool,
  disabled: PropTypes.bool,
  breakPoints: PropTypes.exact({
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xxl: PropTypes.number,
  }),
  ladaOnly: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  extension: PropTypes.bool,
  required: PropTypes.bool,
  ladaDefault: PropTypes.number,
};

LadaNumero.defaultProps = {
  names: defaultNames,
  fullSize: false,
  disabled: false,
  breakPoints: {
    md: null,
    lg: null,
    xl: null,
    xxl: null,
  },
  ladaOnly: false,
  hasFeedback: true,
  extension: false,
  required: false,
  ladaDefault: null,
};

export default LadaNumero;
