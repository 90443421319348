import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEntidadPageByURI } from 'api/entidades';
import { onError } from 'utils/handlers';
import NotFound from 'components/NotFound';
import ServerError from 'components/ServerError';
import Loading from 'components/Loading';
// import Transparencia from 'views/Transparencia'

// Componentes con lo que cuenta actualmente la aplicación
// EJ: [{ key: 'transparencia', component: Transparencia }]
const pages = [];

/**
  Componente intermediario para manejo de sub rutas, aquí se deberá validar que exista la entidad
  y la página a la que se este intentando acceder
  */
const Page = () => {
  const { page: _page, entidad: _entidad } = useParams();
  const [loading, setLoading] = useState(true);
  const [responseStatus, setResponseStatus] = useState(null);
  const [entidad, setEntidad] = useState(null);
  const page = pages.find((p) => p.key === _page);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getEntidadPageByURI(_entidad, _page);
        if (response?.status === 200) {
          setEntidad(response.data);
        }
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
        setResponseStatus(err?.response?.status || 500);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (<Loading size="large" loading={loading} />);
  }

  if (!entidad || !page) {
    if (responseStatus === 404 || !page) {
      return (<NotFound />);
    }
    return (<ServerError />);
  }
  const Component = Page.component;

  return <Component entidad={entidad} />;
};

export default Page;
