// Constants
export const SET_LADAS = 'CATALOGS__SET_LADAS';

const initialState = {
  ladas: [],
};

// Action handlers
const setLadas = (state, payload) => ({ ...state, ladas: payload });

const clear = () => ({ ...initialState });

const ACTION_HANDLER = {
  CLEAR: clear,
  [SET_LADAS]: setLadas,
};

export default (state = initialState, { type, payload }) => {
  const handler = ACTION_HANDLER[type];
  return handler ? handler(state, payload) : state;
};
