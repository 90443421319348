/**
 * Store-main config file
 */
// Dependencies
import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import rootReducer from './rootReducer';

const initialState = {};
const middlewares = [];
const enhancers = [];

export const store = createStore(
  rootReducer(),
  initialState,
  compose(
    applyMiddleware(...middlewares),
    ...enhancers,
  ),
);

export const persistor = persistStore(store);
export default { persistor, store };
