import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Layout,
  Col,
  Row,
  Typography,
} from 'antd';
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
  WechatOutlined,
  MessageOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import A from './A';

const icons = {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
  WechatOutlined,
};

const SocialMediaItem = ({ item }) => {
  const Icon = icons[item.icon];
  if (!Icon) {
    return null;
  }
  return (
    <A style={{ padding: 5, fontSize: 32 }} href={item.url} external>
      <Icon />
    </A>
  );
};

SocialMediaItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
};

const Footer = ({ entidad: { coordenadas, contacto, ...entidad } }) => (
  <AntFooter>
    <Row gutter={[10, 20]} style={{ justifyContent: 'center' }}>
      <Col xs={0} sm={0} md={4} lg={3} xxl={2}>
        <Img src={entidad.logo_vertical} />
      </Col>
      <Col xs={24} sm={24} md={12} lg={14} xl={11} xxl={8}>
        <Title level={4} style={{ fontWeigth: 'bold' }}>
          {`${new Date().getFullYear()} © ${entidad.nombre}`}
        </Title>
        {contacto.email && (
          <RowFlex>
            <MessageOutlined />
            <A href={`mailto:${contacto.email}`} useAnchor>
              <Text>
                {contacto.email}
              </Text>
            </A>
          </RowFlex>
        )}
        {contacto.numero_de_telefono && (
          <RowFlex>
            <PhoneOutlined />
            <A href={`tel:${contacto.lada?.lada}${contacto.numero_de_telefono}`} useAnchor>
              <Text>
                {`${contacto.lada?.lada} ${contacto.numero_de_telefono}`}
              </Text>
            </A>
          </RowFlex>
        )}
        {(coordenadas && contacto.direccion) && (
          <RowFlex>
            <EnvironmentOutlined />
            <A
              href={`https://www.openstreetmap.org/?mlat=${coordenadas.latitud}&mlon=${coordenadas.longitud}#map=17/${coordenadas.latitud}/${coordenadas.longitud}&layers=T`}
              external
            >
              <Text>
                {contacto.direccion}
              </Text>
            </A>
          </RowFlex>
        )}
        {contacto.horario && (
          <RowFlex>
            <ClockCircleOutlined />
            <Text>
              {contacto.horario}
            </Text>
          </RowFlex>
        )}
      </Col>
      {contacto.redes_sociales.some((e) => icons[e.icon]) && (
        <Col xs={24} sm={24} md={8} lg={7} xl={5} xxl={4}>
          <Title level={4}>
            Siguenos en:
          </Title>
          <SocialMedia>
            {contacto.redes_sociales.map((item) => (
              <SocialMediaItem key={item.id} item={item} />
            ))}
          </SocialMedia>
        </Col>
      )}
    </Row>
  </AntFooter>
);

Footer.propTypes = {
  entidad: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    logo_vertical: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    tema: PropTypes.shape({
      color_de_fondo_primario: PropTypes.string.isRequired,
      color_de_fondo_secundario: PropTypes.string.isRequired,
      color_de_texto_primario: PropTypes.string.isRequired,
      color_de_texto_secundario: PropTypes.string.isRequired,
    }).isRequired,
    contacto: PropTypes.shape({
      email: PropTypes.string,
      lada: PropTypes.shape({
        lada: PropTypes.string,
      }).isRequired,
      numero_de_telefono: PropTypes.number,
      direccion: PropTypes.string,
      horario: PropTypes.string,
      redes_sociales: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        icon: PropTypes.string,
      })),
    }),
    coordenadas: PropTypes.shape({
      latitud: PropTypes.number,
      longitud: PropTypes.number,
    }),
    external_link: PropTypes.string,
  }).isRequired,
};

const AntFooter = styled(Layout.Footer)`
  width: 100vw;
  @media only screen and (max-width: 576px) {
    padding: 10px 25px;
  }
`;

const Title = styled(Typography.Title)`
  color: #FFFFFF !important;
  text-decoration: underline;
  text-transform: uppercase;
`;

const Text = styled(Typography.Text)`
  font-size: 16px;
  color: #FFFFFF !important;
`;

const Img = styled.img.attrs({ draggable: false })`
  max-width: 100px;
  height: 100%;
  margin: auto;
  object-fit: cover;
  user-select: none;
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span.ant-typography {
    padding-left: 5px;
    font-size: 16px;
  }
`;

export default Footer;
