import API from 'api';

const URI = 'entidades';

export const getPaginaMunicipalByURI = async (url) => {
  const response = await API.get(`tramites/pagina-municipal/${url}`);
  if (response?.status === 200) {
    return response?.data;
  }
  return null;
};

export const getEntidadPageByURI = async (url, page) => {
  // TODO: por definir como usar el param 'page'
  const response = await API.get(`${URI}/landing`, {
    params: { url, page },
  });
  if (response?.status === 200) {
    return response?.data?.[page];
  }
  return null;
};

export default {
  getPaginaMunicipalByURI,
  getEntidadPageByURI,
};
