// Constants
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_THEME = 'SET_THEME';
export const CLEAR_APP = 'CLEAR_APP';

// Initial state
const initialState = {
  collapsedSidebar: false,
  theme: {},
};

// Action handlers
const toggleSidebar = (state, payload) => ({ ...state, collapsedSidebar: payload });
const setTheme = (state, payload) => ({ ...state, theme: payload });
const clear = () => ({ ...initialState });

const ACTION_HANDLER = {
  [SET_THEME]: setTheme,
  [TOGGLE_SIDEBAR]: toggleSidebar,
  [CLEAR_APP]: clear,
};

export default (state = initialState, { type, payload }) => {
  const handler = ACTION_HANDLER[type];
  return handler ? handler(state, payload) : state;
};
