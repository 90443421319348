import { createGlobalStyle } from 'styled-components';

const globalStyles = createGlobalStyle`
  .ant-layout-header, .ant-drawer-content, .ant-layout-footer {
    background: ${({ theme }) => theme.color_de_fondo_primario};
    * {
      color: #FFFFFF;
      font-weight: bold;
      background: ${({ theme }) => theme.color_de_fondo_primario};
    }
  }

  .ant-drawer-header button {
    max-height: 100%;
  }
  .ant-menu-item-selected, .ant-menu-item-selected * {
    background: ${({ theme }) => theme.color_de_fondo_secundario} !important;
    :after {
      display: none;
    }
  }

  .ant-menu-item-active:not(.ant-menu-item-selected),
  .ant-menu-item:not(.ant-menu-item-selected):hover {
    background: ${({ theme }) => theme.color_de_fondo_primario};
    color: ${({ theme }) => theme.color_de_texto_secundario};
    * {  
      color: ${({ theme }) => theme.color_de_texto_secundario};
    }
    :after {
      border-bottom: 3px solid ${({ theme }) => theme.color_de_fondo_secundario} !important;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${({ theme }) => theme.color_de_fondo_primario};
  }

  .ant-typography b, .ant-typography strong {
    color: ${({ theme }) => theme.color_de_texto_secundario};
  }

  .primary-color {
    color: ${({ theme }) => theme.color_de_texto_primario} !important;
  }

  .secondary-color, .secondary-color * {
    color: ${({ theme }) => theme.color_de_texto_secundario} !important;
  }
  .secondary-background, .secondary-background * {
    background: ${({ theme }) => theme.color_de_fondo_secundario} !important;
  }

  .ant-form button {
    background: ${({ theme }) => theme.color_de_fondo_primario};
    &:hover, &:focus, &:active {
      text-decoration: none;
      background: ${({ theme }) => theme.color_de_fondo_primario};
    }
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color_de_fondo_secundario};
  }
`;

export default globalStyles;
