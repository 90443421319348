import API from 'api';
import { onError } from 'utils/handlers';

export const setLadas = async (dispatch, type) => {
  try {
    const response = await API.get('usuarios/lada/');
    if (response?.status === 200) {
      dispatch({
        type,
        payload: response?.data,
      });
      return response?.data;
    }
  } catch (err) {
    onError(err);
  }
  return [];
};

export default {
  setLadas,
};
