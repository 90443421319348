/* eslint-disable react/no-danger */
import React from 'react';
import htmlAsString from './htmlAsString';
import './MiGob.css';
import './nicepage.scss';

const createMarkup = () => ({ __html: htmlAsString });

const Landing = () => (

  <div
    style={{ height: '100vh', width: '100vw', overflowY: 'scroll' }}
    id="landing"
    dangerouslySetInnerHTML={createMarkup()}
  />
);

export default Landing;
