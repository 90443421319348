import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Layout as AntLayout, Grid, Drawer } from 'antd';
import GlobalStyles from './global-styles';
import Header, { MenuItems, HEADER_HEIGHT } from './Header';
import Footer from './Footer';

const Layout = ({ children, entidad }) => {
  const screens = Grid.useBreakpoint();
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedey] = useState('home');

  useEffect(() => {
    const mainScrollable = document.querySelector('#root > div');
    const handleScroll = () => {
      const { scrollTop } = mainScrollable;
      const windowHeigth = document.body.clientHeight - HEADER_HEIGHT;
      let key = null;
      const sections = mainScrollable.querySelectorAll('.section');
      sections.forEach((section) => {
        const sectionHeigth = section.offsetHeight;
        const sectionTop = section.offsetTop;
        const sectionIsVisible = scrollTop > sectionTop - windowHeigth
          && scrollTop < sectionTop + windowHeigth;
        const screenMiddle = scrollTop + (windowHeigth / 2);
        const sectionAboveMiddleScreen = screenMiddle > sectionTop
          && screenMiddle < sectionTop + sectionHeigth;
        if (!key && sectionIsVisible && sectionAboveMiddleScreen) {
          key = section.id;
        }
      });
      if (key !== selectedKey || (!key && selectedKey === 'about')) {
        setSelectedey(key || 'home');
      }
    };
    mainScrollable.addEventListener('scroll', handleScroll);
    return () => mainScrollable.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ThemeProvider theme={entidad.tema}>
      <GlobalStyles />
      <AntLayout>
        <Header
          entidad={entidad}
          screens={screens}
          setCollapsed={setCollapsed}
          collapsed={collapsed}
          selectedKey={selectedKey}
        />
        <Content>
          { children }
        </Content>
        <Footer entidad={entidad} />
      </AntLayout>
      {!screens.md && (
        <Drawer
          title="Menú"
          placement="left"
          visible={!collapsed && screens.md === false}
          onClose={() => setCollapsed(true)}
          bodyStyle={{ padding: 0 }}
        >
          <MenuItems
            mode="vertical"
            setCollapsed={setCollapsed}
            selectedKey={selectedKey}
            collapsed={collapsed}
            entidad={entidad}
          />
        </Drawer>
      )}
    </ThemeProvider>
  );
};

const Content = styled(AntLayout.Content)`
  left: 0;
  width: 100vw;
  /* height: calc(100vh - (64px + 200px)); */
  /* overflow: hidden scroll; */
  padding: 0;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  entidad: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    tema: PropTypes.shape({
      color_de_fondo_primario: PropTypes.string.isRequired,
      color_de_fondo_secundario: PropTypes.string.isRequired,
      color_de_texto_primario: PropTypes.string.isRequired,
      color_de_texto_secundario: PropTypes.string.isRequired,
    }).isRequired,
    external_link: PropTypes.string,
  }).isRequired,
};

export default Layout;
