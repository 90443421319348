import React from 'react';
import {
  Row,
  Layout,
  Menu,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { MenuOutlined, LinkOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const ToggleSidebarButton = ({ setCollapsed, collapsed }) => React.createElement(MenuOutlined, {
  style: {
    fontSize: '28px',
    lineHeight: '64px',
    padding: '0 26px',
  },
  onClick: () => setCollapsed(!collapsed),
});

ToggleSidebarButton.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
};

ToggleSidebarButton.defaultProps = {
  collapsed: false,
};

export const HEADER_HEIGHT = 64;

export const MenuItems = ({
  mode,
  setCollapsed,
  selectedKey,
  collapsed,
  entidad,
}) => (
  <Menu
    mode={mode}
    selectedKeys={[selectedKey]}
    onClick={({ key }) => {
      const section = document.getElementById(key);
      const scrollableEl = document.querySelector('#root > div');
      scrollableEl.scrollTo({ top: section.offsetTop - 64, behavior: 'smooth' });
      if (!collapsed) {
        setCollapsed(true);
      }
    }}
  >
    <Menu.Item key="home">
      Inicio
    </Menu.Item>
    <Menu.Item key="about">
      Nosotros
    </Menu.Item>
    {!!entidad.noticias.length && (
      <Menu.Item key="news">
        Noticias
      </Menu.Item>
    )}
    <Menu.Item key="contact">
      Contacto
    </Menu.Item>
  </Menu>
);

MenuItems.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  selectedKey: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
  mode: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
  entidad: PropTypes.shape({
    noticias: PropTypes.arrayOf(PropTypes.object),
  }),
};

MenuItems.defaultProps = {
  collapsed: false,
  entidad: {
    noticias: [],
  },
};

const Header = ({
  entidad,
  collapsed,
  setCollapsed,
  screens,
  selectedKey,
}) => (
  <Layout.Header style={{
    padding: screens.md ? '0 50px' : 0,
    position: 'fixed',
    zIndex: 2,
    width: '100vw',
  }}
  >
    <Row align="middle">
      <Row>
        {screens.md === false
            && (
              <ToggleSidebarButton
                setCollapsed={setCollapsed}
                collapsed={collapsed}
              />
            )}
        {screens.md && (
          <div className="logo-wrapper">
            <Logo
              src={entidad.logo}
              alt="logo"
            />
          </div>
        )}
      </Row>
      {screens.md && (
      <MenuItems
        mode="horizontal"
        setCollapsed={setCollapsed}
        selectedKey={selectedKey}
        collapsed={collapsed}
        entidad={entidad}
      />
      )}
      {entidad.sitio_externo && (
        <Button
          className={screens.md ? 'secondary-background' : 'secondary-color'}
          type={screens.md ? 'default' : 'link'}
          style={{ marginLeft: 'auto' }}
        >
          <a href={entidad.sitio_externo} target="_blank" rel="noreferrer">
            {screens.md ? 'Sitio' : <LinkOutlined /> }
          </a>
        </Button>
      )}
    </Row>
  </Layout.Header>
);

Header.propTypes = {
  entidad: PropTypes.shape({
    sitio_externo: PropTypes.string,
    logo: PropTypes.string.isRequired,
  }).isRequired,
  setCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  screens: PropTypes.shape({ md: PropTypes.bool }),
  selectedKey: PropTypes.string.isRequired,
};

Header.defaultProps = {
  collapsed: false,
  screens: { md: false },
};

const Logo = styled.img`
  width: 150px;
  padding-right: 15px;
`;

export default Header;
