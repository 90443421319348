/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Grid,
  Form,
  Input,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import NotFound from 'components/NotFound';
import ServerError from 'components/ServerError';
import fallback from 'assets/fallback.png';
import { getPaginaMunicipalByURI } from 'api/entidades';
import Loading from 'components/Loading';
import LadaNumero from 'components/LadaNumero';
import { onError } from 'utils/handlers';
import A from './components/A';
import { HEADER_HEIGHT } from './components/Header';
import Layout from './components/Layout';
import favicon from './favicon.ico';

const Dots = () => (
  <div>
    {Array(11).fill(0).map((e, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Row key={idx}>
        {Array(16).fill(0).map((i, _idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={_idx}>
            <Dot />
          </Col>
        ))}
      </Row>
    ))}
  </div>
);

const LandingEntidad = () => {
  const [form] = Form.useForm();
  const { entidad: _entidad } = useParams();
  const screens = Grid.useBreakpoint();
  const [responseStatus, setResponseStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [entidad, setEntidad] = useState(null);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
    email: [
      requiredRule,
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
  };

  const setIconAndTitle = (entidadFount = {}) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = entidadFount.logo_vertical || favicon;
    document.title = entidadFount.nombre_corto || 'MiGob';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entidadFound = await getPaginaMunicipalByURI(_entidad);
        if (entidadFound) {
          setEntidad(entidadFound);
          setIconAndTitle(entidadFound);
        } else {
          setResponseStatus(404);
        }
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
        setResponseStatus(err?.response?.status || 500);
      }
    };
    fetchData();

    return () => {
      setIconAndTitle();
    };
  }, []);

  if (loading) {
    return (<Loading size="large" loading={loading} />);
  }

  if (!entidad) {
    if (responseStatus === 404) {
      return (<NotFound />);
    }
    return (<ServerError />);
  }

  const getShadowSpan = () => {
    if (screens.xxl) {
      return 8;
    }
    if (screens.md) {
      return 12;
    }
    return 24;
  };

  const Banner = () => (
    <BannerWrapper className="section" id="home">
      <ImgBanner src={entidad.banner.imagen || fallback} alt="banner entidad" />
      {entidad.banner.usar_sombra && <Shadow />}
      <Row align="middle" style={{ height: '100%' }}>
        <Col span={getShadowSpan()} style={{ padding: 25 }}>
          <Title level={1} style={{ fontWeigth: 'bold', color: '#FFFFFF' }}>
            <div dangerouslySetInnerHTML={{ __html: entidad.banner.titulo }} />
          </Title>
          {!screens.xs && (
            <Text style={{ fontSize: 16, color: '#FFFFFF' }}>
              <div dangerouslySetInnerHTML={{ __html: entidad.banner.texto }} />
            </Text>
          )}
        </Col>
      </Row>
    </BannerWrapper>
  );

  const Services = () => (
    <ServicesContainer>
      <ServicesWrapper
        justifyContent={
          document.body.offsetWidth * 0.8 >= entidad.servicios.length * 150 && screens.md
        }
      >
        {entidad.servicios.map((service) => (
          <ServiceWrapper key={service.id}>
            <Service>
              <A href={service.url} external>
                <Img src={service.imagen} />
              </A>
            </Service>
          </ServiceWrapper>
        ))}
      </ServicesWrapper>
    </ServicesContainer>
  );

  const About = () => (
    <div className="section" id="about" style={{ padding: 20, marginTop: 10 }}>
      <Title className="primary-color" style={{ margin: '10px 0 0 0' }}>Nosotros</Title>
      <AboutContainer>
        {entidad.about_items.map((item) => (
          <AboutItem key={item.id}>
            <Col span={item.imagen && screens.md ? 12 : 24}>
              <Title level={3} className="primary-color">
                {item.nombre}
              </Title>
              <Text>
                <div dangerouslySetInnerHTML={{ __html: item.contenido }} />
              </Text>
            </Col>
            {item.imagen && (
              <Col span={screens.md ? 12 : 24} style={{ maxHeight: 250 }}>
                <Img src={item.imagen} style={{ maxHeight: 250 }} />
              </Col>
            )}
          </AboutItem>
        ))}
      </AboutContainer>
    </div>
  );

  const Contact = () => (
    <ContactWrapper className="section" id="contact">
      <Col xs={0} sm={0} md={10} lg={12} style={{ padding: screens.lg ? 20 : 10 }}>
        <Dots />
        <ContactImg src={entidad.contacto.imagen} />
        <ContactTextWrapper>
          <Title level={4}>
            <div dangerouslySetInnerHTML={{ __html: entidad.contacto.texto }} />
          </Title>
        </ContactTextWrapper>
      </Col>
      <Col xs={24} sm={24} md={14} lg={12}>
        <Title className="primary-color" style={{ textAlign: 'center' }}>
          Contactanos
        </Title>
        <Form
          form={form}
          layout="vertical"
          style={{ padding: screens.md ? '10px 40px' : '10px 20px' }}
        >
          <Row gutter={10}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Nombre completo" name="nombre" rules={rules.required}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Correo electrónico" name="correo_electronico" rules={rules.email}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Asunto" name="asunto" rules={rules.required}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <LadaNumero ladaDefault={entidad.lada?.id} fullSize required />
            </Col>
            <Col span={24}>
              <Form.Item label="Comentario" name="comentario" rules={rules.required}>
                <Input.TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button style={{ color: '#FFFFFF', fontWeight: 'bold' }} block htmlType="submit">
                  Enviar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </ContactWrapper>
  );

  const NewsItem = ({ item, width }) => (
    <NewsCard style={{ width }}>
      <Img src={item.imagen} />
      <NewsCardContentContainer>
        <Text style={{ fontWeight: 'bold', color: '#404040' }}>{item.fecha}</Text>
        <Title style={{ margin: 0 }} level={4} className="primary-color">
          {item.titulo}
        </Title>
        <Text className="ellipsis">{item.contenido}</Text>
        <Row justify="end" style={{ paddingTop: 10 }}>
          <NewsLink href={item.enlace} external>
            <Text style={{ color: 'inherit' }}>Saber más</Text>
            <RightOutlined />
          </NewsLink>
        </Row>
      </NewsCardContentContainer>
    </NewsCard>
  );

  NewsItem.propTypes = {
    item: PropTypes.shape({
      fecha: PropTypes.string,
      imagen: PropTypes.string,
      titulo: PropTypes.string,
      contenido: PropTypes.string,
      enlace: PropTypes.string,
    }).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  };

  const NewsGallery = () => (
    <Row gutter={[15, 15]}>
      {entidad.noticias.map((item) => (
        <Col xs={24} sm={24} md={8} xxl={6} key={item.id}>
          <NewsItem width="100%" item={item} />
        </Col>
      ))}
    </Row>
  );

  const NewsCarrousel = () => (
    <NewsCarrouselContainer
      justifyContent={
          document.body.offsetWidth * 0.8 >= entidad.servicios.length * 150 && screens.md
        }
    >
      {entidad.noticias.map((item) => (
        <NewsItemWrapper key={item.id}>
          <NewsItem item={item} width={300} />
        </NewsItemWrapper>
      ))}
    </NewsCarrouselContainer>
  );

  const News = () => {
    if (!entidad.noticias.length) {
      return null;
    }
    const NewsComponent = entidad.noticias.length < 8 ? NewsGallery : NewsCarrousel;
    return (
      <div className="section" id="news" style={{ padding: 15 }}>
        <Title level={2} className="primary-color">Noticias</Title>
        <NewsComponent />
      </div>
    );
  };

  return (
    <Layout entidad={entidad}>
      <Banner />
      <Services />
      <About />
      <News />
      <Contact />
    </Layout>
  );
};

const BannerWrapper = styled.div`
  width: 100vw;
  height: 50vh;
  position: relative;
  overflow: hidden;
  margin-top: ${HEADER_HEIGHT}px;
`;

const Shadow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: linear-gradient(89.54deg,
  rgba(9, 9, 9, 0.76) 0.43%,
  rgba(9, 9, 9, 0.415625) 52.63%,
  rgba(9, 9, 9, 0.324279) 73.81%,
  rgba(9, 9, 9, 0.175111) 89.32%,
  rgba(9, 9, 9, 0.0787997) 99.64%,
    rgba(9, 9, 9, 0) 99.65%);
`;

const Img = styled.img.attrs({ draggable: false })`
  width: 100%;
  object-fit: cover;
  user-select: none;
`;

const ImgBanner = styled(Img)`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Title = styled(Typography.Title)``;

const Text = styled(Typography.Text)`
  font-size: 16px;
`;

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  @media only screen and (min-width: 767px) {
    height: 0;
    margin-bottom: 40px;
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.justifyContent ? 'center' : '')};
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  @media only screen and (min-width: 767px) {
    z-index: 1;
    width: 80vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
  }
`;

const ServiceWrapper = styled.div`
  scroll-snap-align: start;
  padding: 10px;
`;

const Service = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 15px;
`;

const AboutContainer = styled(Col)`
  .ant-row:nth-child(even) {
    flex-flow: row-reverse wrap;
  }
`;

const AboutItem = styled(Row).attrs({ gutter: 15 })`
  width: 100%;
  padding: 15px 0px;
  img {
    border-radius: 12px;
  }
`;

const NewsCarrouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.justifyContent ? 'center' : '')};
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  width: 100vw;
`;

const NewsItemWrapper = styled.div`
  scroll-snap-align: start;
  padding: 10px;
`;

const NewsCard = styled.div`
  border-radius: 12px;
  box-shadow: 1px 0px 2px 2px #cacaca;
  background-color: #FFFFFF;
  height: 410px;
  img {
    border-radius: 12px;
    height: 200px;
  }
  span.ant-typography.ellipsis {
    -webkit-line-clamp: 4;
    height: 100px;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  max-width: calc(100vw - 50px);
`;

const NewsCardContentContainer = styled.div`
  padding: 15px;
`;

const ContactWrapper = styled(Row)`
  max-height: clamp(450px, 30vw, 35vw);
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    max-height: 600px;
  }
`;

const NewsLink = styled(A)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 4px;
  background-color: ${({ theme }) => theme.color_de_fondo_primario};
`;

const ContactImg = styled(Img)`
  width: clamp(50%, 70%, 80%);
  max-height: 300px;
  transform: translateX(80px) translateY(-80px);
`;

const ContactTextWrapper = styled.div`
  width: clamp(300px, 50%, 60%);
  transform: translateX(80%) translateY(-200px);
  background: ${({ theme }) => theme.color_de_fondo_primario};
  text-align: center;
  padding: 25px;
  .ant-typography {
    color: #FFFFFF;
  }
`;

export default LandingEntidad;
