import React from 'react';
import PropTypes from 'prop-types';
import {
  Select as AntSelect,
  TreeSelect,
} from 'antd';

const onClickTitle = (e) => {
  const element = e.target;
  if (element) {
    let parent = element.parentElement;
    parent = parent ? parent.parentElement : null;
    if (parent) {
      const clickableSpan = parent.previousSibling;
      if (clickableSpan) {
        clickableSpan.click();
      }
    }
  }
};

const renderTitle = (title, bold) => (
  // eslint-disable-next-line
  <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
    {title}
  </span>
);

const normalizeText = (t = '') => t?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

const filterOptionDefault = (inp, op) => {
  const input = normalizeText(inp);
  const option = normalizeText(op.label);
  return option.indexOf(input) !== -1;
};

const filterOptionForTree = (_input, _option) => {
  const option = normalizeText(_option.title.props.children);
  const input = normalizeText(_input);
  return option.indexOf(input) !== -1;
};

const trueFalseOps = [{ label: 'Sí', value: true }, { label: 'No', value: false }];
const defRender = (item) => item?.descripcion
  || (typeof item === 'string' || typeof item === 'number' ? item : '');

const normalizeData = (_data = [], keyProp, labelProp, valueProp, childrenProp) => {
  const output = _data.map((item) => {
    const clone = { ...item };
    const children = normalizeData(clone[childrenProp], keyProp,
      labelProp, valueProp, childrenProp);
    const key = clone[keyProp];
    const label = clone[labelProp];
    const value = clone[valueProp];
    const title = renderTitle(keyProp ? `${key} - ${label}` : label);
    if (!children.length) {
      delete clone.children;
      return ({
        title,
        value,
      });
    }
    return ({
      title,
      value,
      children,
      selectable: false,
    });
  });
  return output;
};

const Select = ({
  filterOption,
  render,
  dataSource,
  keyProp,
  labelProp,
  disabled,
  valueProp,
  keyLabelRender,
  trueFalse,
  normalize,
  tree,
  childrenProp,
  ...props
}) => {
  const keyLabelRenderFunc = (item) => `${item[keyProp]} - ${item[labelProp]}`;

  const getLabel = (e) => {
    if (keyLabelRender) {
      return keyLabelRenderFunc(e);
    }
    if (render) {
      return render(e);
    }
    if (labelProp) {
      return defRender(e[labelProp]);
    }
    return defRender(e);
  };

  const options = trueFalse ? trueFalseOps : dataSource.map((e) => ({
    label: getLabel(e),
    value: e[valueProp],
    disabled: !!e.disabled,
  }));

  if (tree) {
    const treeData = normalize
      ? normalizeData(dataSource, keyProp, labelProp, valueProp, childrenProp) : dataSource;
    return (
      <TreeSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        showArrow={!disabled}
        disabled={disabled}
        allowClear
        showSearch
        treeNodeFilterProp="children"
        filterOption={filterOptionForTree}
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={treeData}
        notFoundContent={dataSource.length ? 'No hay coincidencias' : 'No hay datos'}
      />
    );
  }

  return (
    <AntSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      allowClear
      showSearch
      disabled={disabled}
      showArrow={!disabled}
      optionFilterProp="children"
      filterOption={filterOption}
      notFoundContent={dataSource.length ? 'No hay coincidencias' : 'No hay datos'}
      options={options}
      dropdownClassName="wrap-text"
    />
  );
};

Select.propTypes = {
  filterOption: PropTypes.func,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  render: PropTypes.func,
  valueProp: PropTypes.string,
  keyProp: PropTypes.string,
  disabled: PropTypes.bool,
  keyLabelRender: PropTypes.bool,
  labelProp: PropTypes.string,
  trueFalse: PropTypes.bool,
  tree: PropTypes.bool,
  normalize: PropTypes.bool,
  childrenProp: PropTypes.string,
};

Select.defaultProps = {
  filterOption: filterOptionDefault,
  dataSource: [],
  render: null,
  valueProp: 'id',
  keyProp: 'clave',
  disabled: false,
  keyLabelRender: false,
  labelProp: 'descripcion',
  trueFalse: false,
  tree: false,
  normalize: true,
  childrenProp: 'children',
};

export default Select;
