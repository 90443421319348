import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const Loading = ({
  loading: spinning,
  tip,
  size,
  children,
}) => (
  <Spin
    tip={tip}
    spinning={spinning}
    size={size}
  >
    {children && children}
  </Spin>
);

Loading.propTypes = {
  loading: PropTypes.bool,
  tip: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
};

Loading.defaultProps = {
  loading: false,
  tip: 'Cargando ...',
  size: 'middle',
  children: null,
};

export default Loading;
