import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import styled from 'styled-components';
import Landing from 'views/Landing/index';
import LandingEntidad from 'views/LandingEntidad/index';
import Page from 'views/Page/index';

const AppRouter = () => (
  <Router>
    <AppContainer>
      <Switch>
        <Route path="/" exact>
          <Landing />
        </Route>
        <Route path="/:entidad" exact>
          <LandingEntidad />
        </Route>
        <Route path="/:entidad/:page" exact>
          <Page />
        </Route>
      </Switch>
    </AppContainer>
  </Router>
);

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
`;

export default AppRouter;
