// Constants
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SET_PERIODO_FISCAL_SELECTED = 'SET_PERIODO_FISCAL_SELECTED';
export const UPDATE_ENTIDAD_INFO = 'UPDATE_ENTIDAD_INFO';

// Initial state
const initialState = {
  isAuthenticated: false,
  token: null,
  user: null,
  roles: [],
  refreshToken: null,
  entidad: null,
  periodoFiscalSelected: null,
};

// Action handlers
const handleLogin = (state, payload) => {
  if (payload) {
    const {
      token,
      refreshToken,
      roles,
      permisos,
      periodo_fiscal: periodoFiscalSelected,
      entidad_municipal: entidad,
      ...user
    } = payload;

    return {
      ...state,
      isAuthenticated: true,
      token,
      refreshToken,
      user,
      roles,
      entidad,
      permisos,
      periodoFiscalSelected,
    };
  }
  return state;
};

const handleLogout = () => initialState;

const handleRefreshToken = (state, payload) => ({
  ...state,
  token: payload,
});

const handleUserInfo = (state, payload) => ({ ...state, user: { ...state.user, ...payload } });

const handleEntidadInfo = (state, payload) => ({
  ...state,
  entidad: {
    ...state.entidad,
    ...payload,
  },
});

const handlePeriodoFiscal = (state, payload) => ({
  ...state,
  periodoFiscalSelected: payload,
});

const ACTION_HANDLER = {
  [LOGIN]: handleLogin,
  [LOGOUT]: handleLogout,
  [UPDATE_USER_INFO]: handleUserInfo,
  [UPDATE_ENTIDAD_INFO]: handleEntidadInfo,
  [REFRESH_TOKEN]: handleRefreshToken,
  [SET_PERIODO_FISCAL_SELECTED]: handlePeriodoFiscal,
};

export default (state = initialState, { type, payload }) => {
  const handler = ACTION_HANDLER[type];
  return handler ? handler(state, payload) : state;
};
